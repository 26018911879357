module.exports=[
  "Artist",
  "Headline",
  "Curfew",
  "Doors",
  "Sound Check",
  "Load In",
  "Support",
  "Changeover"
]

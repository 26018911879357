module.exports={
  "ADP": { "code": "ADP", "name": "Andorran Peseta", "weight": 0 },
  "AED": { "code": "AED", "name": "United Arab Emirates Dirham", "weight": 0 },
  "AFA": { "code": "AFA", "name": "Afghanistan Afghani", "weight": 0 },
  "ALL": { "code": "ALL", "name": "Albanian Lek", "weight": 0 },
  "ANG": { "code": "ANG", "name": "Netherlands Antillian Guilder", "weight": 0 },
  "AOK": { "code": "AOK", "name": "Angolan Kwanza", "weight": 0 },
  "ARS": { "code": "ARS", "name": "Argentine Peso", "weight": 0 },
  "AUD": { "code": "AUD", "name": "Australian Dollar", "weight": 0 },
  "AWG": { "code": "AWG", "name": "Aruban Florin", "weight": 0 },
  "BBD": { "code": "BBD", "name": "Barbados Dollar", "weight": 0 },
  "BDT": { "code": "BDT", "name": "Bangladeshi Taka", "weight": 0 },
  "BGN": { "code": "BGN", "name": "Bulgarian Lev", "weight": 0 },
  "BHD": { "code": "BHD", "name": "Bahraini Dinar", "weight": 0 },
  "BIF": { "code": "BIF", "name": "Burundi Franc", "weight": 0 },
  "BMD": { "code": "BMD", "name": "Bermudian Dollar", "weight": 0 },
  "BND": { "code": "BND", "name": "Brunei Dollar", "weight": 0 },
  "BOB": { "code": "BOB", "name": "Bolivian Boliviano", "weight": 0 },
  "BRL": { "code": "BRL", "name": "Brazilian Real", "weight": 0 },
  "BSD": { "code": "BSD", "name": "Bahamian Dollar", "weight": 0 },
  "BTN": { "code": "BTN", "name": "Bhutan Ngultrum", "weight": 0 },
  "BUK": { "code": "BUK", "name": "Burma Kyat", "weight": 0 },
  "BWP": { "code": "BWP", "name": "Botswanian Pula", "weight": 0 },
  "BZD": { "code": "BZD", "name": "Belize Dollar", "weight": 0 },
  "CAD": { "code": "CAD", "name": "Canadian Dollar", "weight": 0 },
  "CHF": { "code": "CHF", "name": "Swiss Franc", "weight": 0 },
  "CLF": { "code": "CLF", "name": "Chilean Unidades de Fomento", "weight": 0 },
  "CLP": { "code": "CLP", "name": "Chilean Peso", "weight": 0 },
  "CNY": { "code": "CNY", "name": "Yuan (Chinese) Renminbi", "weight": 0 },
  "COP": { "code": "COP", "name": "Colombian Peso", "weight": 0 },
  "CRC": { "code": "CRC", "name": "Costa Rican Colon", "weight": 0 },
  "CZK": { "code": "CZK", "name": "Czech Republic Koruna", "weight": 0 },
  "CUP": { "code": "CUP", "name": "Cuban Peso", "weight": 0 },
  "CVE": { "code": "CVE", "name": "Cape Verde Escudo", "weight": 0 },
  "CYP": { "code": "CYP", "name": "Cyprus Pound", "weight": 0 },
  "DKK": { "code": "DKK", "name": "Danish Krone", "weight": 0 },
  "DOP": { "code": "DOP", "name": "Dominican Peso", "weight": 0 },
  "DZD": { "code": "DZD", "name": "Algerian Dinar", "weight": 0 },
  "ECS": { "code": "ECS", "name": "Ecuador Sucre", "weight": 0 },
  "EGP": { "code": "EGP", "name": "Egyptian Pound", "weight": 0 },
  "EEK": { "code": "EEK", "name": "Estonian Kroon (EEK)", "weight": 0 },
  "ETB": { "code": "ETB", "name": "Ethiopian Birr", "weight": 0 },
  "EUR": { "code": "EUR", "name": "Euro", "weight": 0 },
  "FJD": { "code": "FJD", "name": "Fiji Dollar", "weight": 0 },
  "FKP": { "code": "FKP", "name": "Falkland Islands Pound", "weight": 0 },
  "GBP": { "code": "GBP", "name": "British Pound", "weight": -1 },
  "GHC": { "code": "GHC", "name": "Ghanaian Cedi", "weight": 0 },
  "GIP": { "code": "GIP", "name": "Gibraltar Pound", "weight": 0 },
  "GMD": { "code": "GMD", "name": "Gambian Dalasi", "weight": 0 },
  "GNF": { "code": "GNF", "name": "Guinea Franc", "weight": 0 },
  "GTQ": { "code": "GTQ", "name": "Guatemalan Quetzal", "weight": 0 },
  "GWP": { "code": "GWP", "name": "Guinea-Bissau Peso", "weight": 0 },
  "GYD": { "code": "GYD", "name": "Guyanan Dollar", "weight": 0 },
  "HKD": { "code": "HKD", "name": "Hong Kong Dollar", "weight": 0 },
  "HNL": { "code": "HNL", "name": "Honduran Lempira", "weight": 0 },
  "HTG": { "code": "HTG", "name": "Haitian Gourde", "weight": 0 },
  "HUF": { "code": "HUF", "name": "Hungarian Forint", "weight": 0 },
  "IDR": { "code": "IDR", "name": "Indonesian Rupiah", "weight": 0 },
  "IEP": { "code": "IEP", "name": "Irish Punt", "weight": 0 },
  "ILS": { "code": "ILS", "name": "Israeli Shekel", "weight": 0 },
  "INR": { "code": "INR", "name": "Indian Rupee", "weight": 0 },
  "IQD": { "code": "IQD", "name": "Iraqi Dinar", "weight": 0 },
  "IRR": { "code": "IRR", "name": "Iranian Rial", "weight": 0 },
  "JMD": { "code": "JMD", "name": "Jamaican Dollar", "weight": 0 },
  "JOD": { "code": "JOD", "name": "Jordanian Dinar", "weight": 0 },
  "JPY": { "code": "JPY", "name": "Japanese Yen", "weight": 0 },
  "KES": { "code": "KES", "name": "Kenyan Schilling", "weight": 0 },
  "KHR": { "code": "KHR", "name": "Kampuchean (Cambodian) Riel", "weight": 0 },
  "KMF": { "code": "KMF", "name": "Comoros Franc", "weight": 0 },
  "KPW": { "code": "KPW", "name": "North Korean Won", "weight": 0 },
  "KRW": { "code": "KRW", "name": "(South) Korean Won", "weight": 0 },
  "KWD": { "code": "KWD", "name": "Kuwaiti Dinar", "weight": 0 },
  "KYD": { "code": "KYD", "name": "Cayman Islands Dollar", "weight": 0 },
  "LAK": { "code": "LAK", "name": "Lao Kip", "weight": 0 },
  "LBP": { "code": "LBP", "name": "Lebanese Pound", "weight": 0 },
  "LKR": { "code": "LKR", "name": "Sri Lanka Rupee", "weight": 0 },
  "LRD": { "code": "LRD", "name": "Liberian Dollar", "weight": 0 },
  "LSL": { "code": "LSL", "name": "Lesotho Loti", "weight": 0 },
  "LYD": { "code": "LYD", "name": "Libyan Dinar", "weight": 0 },
  "MAD": { "code": "MAD", "name": "Moroccan Dirham", "weight": 0 },
  "MGF": { "code": "MGF", "name": "Malagasy Franc", "weight": 0 },
  "MNT": { "code": "MNT", "name": "Mongolian Tugrik", "weight": 0 },
  "MOP": { "code": "MOP", "name": "Macau Pataca", "weight": 0 },
  "MRO": { "code": "MRO", "name": "Mauritanian Ouguiya", "weight": 0 },
  "MTL": { "code": "MTL", "name": "Maltese Lira", "weight": 0 },
  "MUR": { "code": "MUR", "name": "Mauritius Rupee", "weight": 0 },
  "MVR": { "code": "MVR", "name": "Maldive Rufiyaa", "weight": 0 },
  "MWK": { "code": "MWK", "name": "Malawi Kwacha", "weight": 0 },
  "MXP": { "code": "MXP", "name": "Mexican Peso", "weight": 0 },
  "MYR": { "code": "MYR", "name": "Malaysian Ringgit", "weight": 0 },
  "MZM": { "code": "MZM", "name": "Mozambique Metical", "weight": 0 },
  "NAD": { "code": "NAD", "name": "Namibian Dollar", "weight": 0 },
  "NGN": { "code": "NGN", "name": "Nigerian Naira", "weight": 0 },
  "NIO": { "code": "NIO", "name": "Nicaraguan Cordoba", "weight": 0 },
  "NOK": { "code": "NOK", "name": "Norwegian Kroner", "weight": 0 },
  "NPR": { "code": "NPR", "name": "Nepalese Rupee", "weight": 0 },
  "NZD": { "code": "NZD", "name": "New Zealand Dollar", "weight": 0 },
  "OMR": { "code": "OMR", "name": "Omani Rial", "weight": 0 },
  "PAB": { "code": "PAB", "name": "Panamanian Balboa", "weight": 0 },
  "PEN": { "code": "PEN", "name": "Peruvian Nuevo Sol", "weight": 0 },
  "PGK": { "code": "PGK", "name": "Papua New Guinea Kina", "weight": 0 },
  "PHP": { "code": "PHP", "name": "Philippine Peso", "weight": 0 },
  "PKR": { "code": "PKR", "name": "Pakistan Rupee", "weight": 0 },
  "PLN": { "code": "PLN", "name": "Polish Zloty", "weight": 0 },
  "PYG": { "code": "PYG", "name": "Paraguay Guarani", "weight": 0 },
  "QAR": { "code": "QAR", "name": "Qatari Rial", "weight": 0 },
  "RON": { "code": "RON", "name": "Romanian Leu", "weight": 0 },
  "RWF": { "code": "RWF", "name": "Rwanda Franc", "weight": 0 },
  "SAR": { "code": "SAR", "name": "Saudi Arabian Riyal", "weight": 0 },
  "SBD": { "code": "SBD", "name": "Solomon Islands Dollar", "weight": 0 },
  "SCR": { "code": "SCR", "name": "Seychelles Rupee", "weight": 0 },
  "SDP": { "code": "SDP", "name": "Sudanese Pound", "weight": 0 },
  "SEK": { "code": "SEK", "name": "Swedish Krona", "weight": 0 },
  "SGD": { "code": "SGD", "name": "Singapore Dollar", "weight": 0 },
  "SHP": { "code": "SHP", "name": "St. Helena Pound", "weight": 0 },
  "SLL": { "code": "SLL", "name": "Sierra Leone Leone", "weight": 0 },
  "SOS": { "code": "SOS", "name": "Somali Schilling", "weight": 0 },
  "SRG": { "code": "SRG", "name": "Suriname Guilder", "weight": 0 },
  "STD": { "code": "STD", "name": "Sao Tome and Principe Dobra", "weight": 0 },
  "RUB": { "code": "RUB", "name": "Russian Ruble", "weight": 0 },
  "SVC": { "code": "SVC", "name": "El Salvador Colon", "weight": 0 },
  "SYP": { "code": "SYP", "name": "Syrian Potmd", "weight": 0 },
  "SZL": { "code": "SZL", "name": "Swaziland Lilangeni", "weight": 0 },
  "THB": { "code": "THB", "name": "Thai Baht", "weight": 0 },
  "TND": { "code": "TND", "name": "Tunisian Dinar", "weight": 0 },
  "TOP": { "code": "TOP", "name": "Tongan Paanga", "weight": 0 },
  "TPE": { "code": "TPE", "name": "East Timor Escudo", "weight": 0 },
  "TRY": { "code": "TRY", "name": "Turkish Lira", "weight": 0 },
  "TTD": { "code": "TTD", "name": "Trinidad and Tobago Dollar", "weight": 0 },
  "TWD": { "code": "TWD", "name": "Taiwan Dollar", "weight": 0 },
  "TZS": { "code": "TZS", "name": "Tanzanian Schilling", "weight": 0 },
  "UGX": { "code": "UGX", "name": "Uganda Shilling", "weight": 0 },
  "USD": { "code": "USD", "name": "US Dollar", "weight": 0 },
  "UYU": { "code": "UYU", "name": "Uruguayan Peso", "weight": 0 },
  "VEF": { "code": "VEF", "name": "Venezualan Bolivar", "weight": 0 },
  "VND": { "code": "VND", "name": "Vietnamese Dong", "weight": 0 },
  "VUV": { "code": "VUV", "name": "Vanuatu Vatu", "weight": 0 },
  "WST": { "code": "WST", "name": "Samoan Tala", "weight": 0 },
  "XAG": { "code": "XAG", "name": "Silver, Ounces", "weight": 0 },
  "XAU": { "code": "XAU", "name": "Gold, Ounces", "weight": 0 },
  "XCD": { "code": "XCD", "name": "East Caribbean Dollar", "weight": 0 },
  "XPD": { "code": "XPD", "name": "Palladium Ounces", "weight": 0 },
  "XPT": { "code": "XPT", "name": "Platinum, Ounces", "weight": 0 },
  "YDD": { "code": "YDD", "name": "Democratic Yemeni Dinar", "weight": 0 },
  "YER": { "code": "YER", "name": "Yemeni Rial", "weight": 0 },
  "YUD": { "code": "YUD", "name": "New Yugoslavia Dinar", "weight": 0 },
  "ZAR": { "code": "ZAR", "name": "South African Rand", "weight": 0 },
  "ZMK": { "code": "ZMK", "name": "Zambian Kwacha", "weight": 0 },
  "ZRZ": { "code": "ZRZ", "name": "Zaire Zaire", "weight": 0 },
  "ZWD": { "code": "ZWD", "name": "Zimbabwe Dollar", "weight": 0 },
  "SKK": { "code": "SKK", "name": "Slovak Koruna", "weight": 0 },
  "AMD": { "code": "AMD", "name": "Armenian Dram", "weight": 0 }
}

module.exports=[
  "Airport",
  "Bus",
  "Coach",
  "Ferry",
  "Home",
  "Hotel",
  "Restaurant",
  "Train",
  "Venue",
  "Other"
]

module.exports=[
  { "displayName": "NET",
    "shortName": "NET"
  },
  { "displayName": "+ VAT",
    "shortName": "VAT"
  },
  { "displayName": "Withholding Tax",
    "shortName": "WHT"
  }
]

module.exports={
  "AF": { "name": "Afghanistan", "weight": 0 },
  "AL": { "name": "Albania", "weight": 0 },
  "DZ": { "name": "Algeria", "weight": 0 },
  "AS": { "name": "American Somoa", "weight": 0 },
  "AD": { "name": "Andorra", "weight": 0 },
  "AO": { "name": "Angola", "weight": 0 },
  "AI": { "name": "Anguilla", "weight": 0 },
  "AQ": { "name": "Antarctica", "weight": 0 },
  "AG": { "name": "Antigua and Barbuda", "weight": 0 },
  "AR": { "name": "Argentina", "weight": 0 },
  "AM": { "name": "Armenia", "weight": 0 },
  "AW": { "name": "Aruba", "weight": 0 },
  "AU": { "name": "Australia", "weight": 0 },
  "AT": { "name": "Austria", "weight": 0 },
  "BS": { "name": "Bahamas", "weight": 0 },
  "BH": { "name": "Bahrain", "weight": 0 },
  "BD": { "name": "Bangladesh", "weight": 0 },
  "BB": { "name": "Barbados", "weight": 0 },
  "BY": { "name": "Belarus", "weight": 0 },
  "BE": { "name": "Belgium", "weight": 0 },
  "BZ": { "name": "Belize", "weight": 0 },
  "BJ": { "name": "Benin", "weight": 0 },
  "BM": { "name": "Bermuda", "weight": 0 },
  "BT": { "name": "Bhutan", "weight": 0 },
  "BO": { "name": "Bolivia", "weight": 0 },
  "BA": { "name": "Bosnia and Herzegovina", "weight": 0 },
  "BW": { "name": "Botswana", "weight": 0 },
  "BV": { "name": "Bouvet Island", "weight": 0 },
  "BR": { "name": "Brazil", "weight": 0 },
  "IO": { "name": "British Indian Ocean Territory", "weight": 0 },
  "BN": { "name": "Brunei Darussalam", "weight": 0 },
  "BG": { "name": "Bulgaria", "weight": 0 },
  "BF": { "name": "Burkina Faso", "weight": 0 },
  "BI": { "name": "Burundi", "weight": 0 },
  "KH": { "name": "Cambodia", "weight": 0 },
  "CM": { "name": "Cameroon", "weight": 0 },
  "CA": { "name": "Canada", "weight": 0 },
  "CV": { "name": "Cape Verde", "weight": 0 },
  "KY": { "name": "Cayman Islands", "weight": 0 },
  "CF": { "name": "Central African Republic", "weight": 0 },
  "TD": { "name": "Chad", "weight": 0 },
  "CL": { "name": "Chile", "weight": 0 },
  "CN": { "name": "China", "weight": 0 },
  "CX": { "name": "Christmas Island", "weight": 0 },
  "CC": { "name": "Cocos (Keeling) Islands", "weight": 0 },
  "CO": { "name": "Colombia", "weight": 0 },
  "KM": { "name": "Comoros", "weight": 0 },
  "CG": { "name": "Congo", "weight": 0 },
  "CK": { "name": "Cook Islands", "weight": 0 },
  "CR": { "name": "Costa Rica", "weight": 0 },
  "HR": { "name": "Croatia", "weight": 0 },
  "CU": { "name": "Cuba", "weight": 0 },
  "CY": { "name": "Cyprus", "weight": 0 },
  "CZ": { "name": "Czech Republic", "weight": 0 },
  "DK": { "name": "Denmark", "weight": 0 },
  "DJ": { "name": "Djibouti", "weight": 0 },
  "DM": { "name": "Dominica", "weight": 0 },
  "DO": { "name": "Dominican Republic", "weight": 0 },
  "TP": { "name": "East Timor", "weight": 0 },
  "EC": { "name": "Ecuador", "weight": 0 },
  "EG": { "name": "Egypt", "weight": 0 },
  "SV": { "name": "El Salvador", "weight": 0 },
  "GQ": { "name": "Equatorial Guinea", "weight": 0 },
  "ER": { "name": "Eritrea", "weight": 0 },
  "EE": { "name": "Estonia", "weight": 0 },
  "ET": { "name": "Ethiopia", "weight": 0 },
  "FO": { "name": "Faroe Islands", "weight": 0 },
  "FK": { "name": "Falkland Islands", "weight": 0 },
  "FJ": { "name": "Fiji", "weight": 0 },
  "FI": { "name": "Finland", "weight": 0 },
  "FR": { "name": "France", "weight": 0 },
  "GF": { "name": "French Guiana", "weight": 0 },
  "PF": { "name": "French Polynesia", "weight": 0 },
  "FQ": { "name": "French Southern and Antarctic Lands", "weight": 0 },
  "GA": { "name": "Gabon", "weight": 0 },
  "GM": { "name": "Gambia", "weight": 0 },
  "GE": { "name": "Georgia", "weight": 0 },
  "DE": { "name": "Germany", "weight": 0 },
  "GH": { "name": "Ghana", "weight": 0 },
  "GI": { "name": "Gibraltar", "weight": 0 },
  "GR": { "name": "Greece", "weight": 0 },
  "GL": { "name": "Greenland", "weight": 0 },
  "GD": { "name": "Grenada", "weight": 0 },
  "GP": { "name": "Guadaloupe", "weight": 0 },
  "GU": { "name": "Guam", "weight": 0 },
  "GT": { "name": "Guatemala", "weight": 0 },
  "GN": { "name": "Guinea", "weight": 0 },
  "GW": { "name": "Guinea-Bissau", "weight": 0 },
  "GY": { "name": "Guyana", "weight": 0 },
  "HT": { "name": "Haiti", "weight": 0 },
  "HM": { "name": "Heard Island and McDonald Islands", "weight": 0 },
  "HN": { "name": "Honduras", "weight": 0 },
  "HK": { "name": "Hong Kong", "weight": 0 },
  "HU": { "name": "Hungary", "weight": 0 },
  "IS": { "name": "Iceland", "weight": 0 },
  "IN": { "name": "India", "weight": 0 },
  "ID": { "name": "Indonesia", "weight": 0 },
  "IR": { "name": "Iran", "weight": 0 },
  "IQ": { "name": "Iraq", "weight": 0 },
  "IE": { "name": "Ireland", "weight": 0 },
  "IL": { "name": "Israel", "weight": 0 },
  "IT": { "name": "Italy", "weight": 0 },
  "CI": { "name": "Ivory Coast", "weight": 0 },
  "JM": { "name": "Jamaica", "weight": 0 },
  "JP": { "name": "Japan", "weight": 0 },
  "JO": { "name": "Jordan", "weight": 0 },
  "KZ": { "name": "Kazakhstan", "weight": 0 },
  "KE": { "name": "Kenya", "weight": 0 },
  "KP": { "name": "North Korea", "weight": 0 },
  "KR": { "name": "South Korea", "weight": 0 },
  "KW": { "name": "Kuwait", "weight": 0 },
  "KG": { "name": "Kyrgyzstan", "weight": 0 },
  "LA": { "name": "Laos", "weight": 0 },
  "LV": { "name": "Latvia", "weight": 0 },
  "LN": { "name": "Lebanon", "weight": 0 },
  "LS": { "name": "Lesotho", "weight": 0 },
  "LR": { "name": "Liberia", "weight": 0 },
  "LY": { "name": "Libya", "weight": 0 },
  "LI": { "name": "Liechtenstein", "weight": 0 },
  "LT": { "name": "Lithuania", "weight": 0 },
  "LU": { "name": "Luxembourg", "weight": 0 },
  "MO": { "name": "Macau", "weight": 0 },
  "MK": { "name": "Macedonia", "weight": 0 },
  "MG": { "name": "Madagascar", "weight": 0 },
  "MW": { "name": "Malawi", "weight": 0 },
  "MY": { "name": "Malaysia", "weight": 0 },
  "MV": { "name": "Maldives", "weight": 0 },
  "ML": { "name": "Mali", "weight": 0 },
  "MT": { "name": "Malta", "weight": 0 },
  "MH": { "name": "Marshall Islands", "weight": 0 },
  "MQ": { "name": "Martinique", "weight": 0 },
  "MR": { "name": "Mauritania", "weight": 0 },
  "MU": { "name": "Mauritius", "weight": 0 },
  "YT": { "name": "Mayotte", "weight": 0 },
  "MX": { "name": "Mexico", "weight": 0 },
  "FM": { "name": "Micronesia", "weight": 0 },
  "MD": { "name": "Moldova", "weight": 0 },
  "MC": { "name": "Monaco", "weight": 0 },
  "MN": { "name": "Mongolia", "weight": 0 },
  "MS": { "name": "Montserrat", "weight": 0 },
  "MA": { "name": "Morocco", "weight": 0 },
  "MZ": { "name": "Mozambique", "weight": 0 },
  "MM": { "name": "Myanmar", "weight": 0 },
  "NA": { "name": "Namibia", "weight": 0 },
  "NR": { "name": "Nauru", "weight": 0 },
  "NP": { "name": "Nepal", "weight": 0 },
  "NL": { "name": "Netherlands", "weight": 0 },
  "AN": { "name": "Netherlands Antilles", "weight": 0 },
  "NC": { "name": "New Caledonia", "weight": 0 },
  "NH": { "name": "New Hebrides", "weight": 0 },
  "NZ": { "name": "New Zealand", "weight": 0 },
  "NI": { "name": "Nicaragua", "weight": 0 },
  "NE": { "name": "Niger", "weight": 0 },
  "NG": { "name": "Nigeria", "weight": 0 },
  "NU": { "name": "Niue", "weight": 0 },
  "NF": { "name": "Norfolk Island", "weight": 0 },
  "NO": { "name": "Norway", "weight": 0 },
  "OM": { "name": "Oman", "weight": 0 },
  "PK": { "name": "Pakistan", "weight": 0 },
  "PW": { "name": "Palau", "weight": 0 },
  "PA": { "name": "Panama", "weight": 0 },
  "PG": { "name": "Papua New Guinea", "weight": 0 },
  "PY": { "name": "Paraguay", "weight": 0 },
  "PE": { "name": "Peru", "weight": 0 },
  "PH": { "name": "Philippines", "weight": 0 },
  "PN": { "name": "Pitcairn", "weight": 0 },
  "PL": { "name": "Poland", "weight": 0 },
  "PT": { "name": "Portugal", "weight": 0 },
  "PR": { "name": "Puerto Rico", "weight": 0 },
  "QA": { "name": "Qatar", "weight": 0 },
  "RE": { "name": "Reunion", "weight": 0 },
  "RO": { "name": "Romania", "weight": 0 },
  "RU": { "name": "Russia", "weight": 0 },
  "RW": { "name": "Rwanda", "weight": 0 },
  "KN": { "name": "St. Christopher-Nevis-Anguilla", "weight": 0 },
  "SH": { "name": "St. Helena", "weight": 0 },
  "LC": { "name": "St. Lucia", "weight": 0 },
  "PM": { "name": "St. Pierre and Miquelon", "weight": 0 },
  "VC": { "name": "St. Vincent", "weight": 0 },
  "WS": { "name": "Samoa", "weight": 0 },
  "SM": { "name": "San Marino", "weight": 0 },
  "ST": { "name": "Sao Tome and Principe", "weight": 0 },
  "SA": { "name": "Saudi Arabia", "weight": 0 },
  "SN": { "name": "Senegal", "weight": 0 },
  "SC": { "name": "Seychelles", "weight": 0 },
  "SL": { "name": "Sierra Leone", "weight": 0 },
  "SG": { "name": "Singapore", "weight": 0 },
  "SK": { "name": "Slovakia", "weight": 0 },
  "SI": { "name": "Slovenia", "weight": 0 },
  "SB": { "name": "Solomon Islands", "weight": 0 },
  "SO": { "name": "Somalia", "weight": 0 },
  "ZA": { "name": "South Africa", "weight": 0 },
  "ES": { "name": "Spain", "weight": 0 },
  "LK": { "name": "Sri Lanka", "weight": 0 },
  "SD": { "name": "Sudan", "weight": 0 },
  "SR": { "name": "Surinam", "weight": 0 },
  "SJ": { "name": "Svalbard and Jan Mayen", "weight": 0 },
  "SZ": { "name": "Swaziland", "weight": 0 },
  "SE": { "name": "Sweden", "weight": 0 },
  "CH": { "name": "Switzerland", "weight": 0 },
  "SY": { "name": "Syria", "weight": 0 },
  "TW": { "name": "Taiwan", "weight": 0 },
  "TJ": { "name": "Tajikistan", "weight": 0 },
  "TZ": { "name": "Tanzania", "weight": 0 },
  "TH": { "name": "Thailand", "weight": 0 },
  "TG": { "name": "Togo", "weight": 0 },
  "TK": { "name": "Tokelau Islands", "weight": 0 },
  "TO": { "name": "Tonga", "weight": 0 },
  "TT": { "name": "Trinidad and Tobago", "weight": 0 },
  "TN": { "name": "Tunisia", "weight": 0 },
  "TR": { "name": "Turkey", "weight": 0 },
  "TC": { "name": "Turks and Caicos Islands", "weight": 0 },
  "TV": { "name": "Tuvalu", "weight": 0 },
  "UG": { "name": "Uganda", "weight": 0 },
  "UA": { "name": "Ukraine", "weight": 0 },
  "AE": { "name": "United Arab Emirates", "weight": 0 },
  "GB": { "name": "United Kingdom", "weight": 1 },
  "US": { "name": "United states", "weight": 0 },
  "PU": { "name": "United state Miscellaneous Pacific Islands", "weight": 0 },
  "UY": { "name": "Uruguay", "weight": 0 },
  "UZ": { "name": "Uzbekistan", "weight": 0 },
  "VA": { "name": "Vatican City", "weight": 0 },
  "VE": { "name": "Venezuela", "weight": 0 },
  "VN": { "name": "Vietnam", "weight": 0 },
  "VI": { "name": "Virgin Islands (U.S.)", "weight": 0 },
  "WF": { "name": "Wallis and Futana", "weight": 0 },
  "EH": { "name": "Western Sahara", "weight": 0 },
  "YE": { "name": "Yemen", "weight": 0 },
  "YU": { "name": "Yugoslavia", "weight": 0 },
  "ZR": { "name": "Zaire", "weight": 0 },
  "ZM": { "name": "Zambia", "weight": 0 },
  "ZW": { "name": "Zimbabwe", "weight": 0 },
  "RS": { "name": "Serbia", "weight": 0 },
  "ME": { "name": "Montenegro", "weight": 0 },
  "RS2": { "name": "Republika Srpska", "weight": 0 },
  "XK": { "name": "Kosovo", "weight": 0 },
  "SS": { "name": "South Sudan", "weight": 0 }
}

module.exports=[
  "Electronic"
, "Rock"
, "Alternative"
, "Urban"
, "Indie"
, "Jazz"
, "Pop"
, "Reggae"
, "Showcase"
]
